<template>
  <div class="">
    <center-title title="对账管理"></center-title>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <div class="row-between">
        <div class="row">
          <el-form-item>
            <el-select v-model="form.settle_status" placeholder="确认账单状态">
              <el-option label="全部" value="0"></el-option>
              <el-option label="待确认账单" value="1"></el-option>
              <el-option label="已确认账单" value="2"></el-option>
              <el-option label="已驳回账单" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.pay_status" placeholder="付款账单状态">
              <el-option label="全部" value="0"></el-option>
              <el-option label="待付款账单" value="1"></el-option>
              <el-option label="已付款账单" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker v-model="form.date" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" class="whtie submit" @click="resetList(1)">筛选</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table :data="settlementList">
      <el-table-column align="center" prop="company_name" label="客户名称">
      </el-table-column>
      <el-table-column align="center" prop="settle_sn" label="对账单号">
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="对账时间" width="130px">
      </el-table-column>
      <el-table-column align="center" prop="business_money" label="账单金额">
        <template slot-scope="scope">
          <make-priceformat :price="scope.row.business_money" :subscriptSize="14" :firstSize="14" :secondSize="14"
            color="#333333"></make-priceformat>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="status_text" label="对账状态" width="100px">
        <!-- <template slot-scope="scope">
          {{ scope.row.status ? "未汇款" : "已汇款" }}
        </template> -->
      </el-table-column>
      <!-- <el-table-column align="center" prop="endTime" label="对账期间">
      </el-table-column> -->
      <el-table-column align="center" prop="status" label="操作">
        <template slot-scope="scope">
          <div class="primary cursor mb5" @click="dialogVisible = true, auditBox.id = scope.row.id"
            v-if="scope.row.status === 0 ">审核</div>
          <div class="warn mb5" v-else>已审核</div>
          <!-- <div class="primary mb5 cursor" v-if="(scope.row.status == 1 && !scope.row.pay_img)"
            @click="uploadDialogVisible = true, auditBox.id = scope.row.id">上传支付凭证</div> -->
          <router-link :to="`/financialDetail?id=${scope.row.id}`">查看明细</router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="对账审核" :visible.sync="dialogVisible" width="30%">
      <el-radio-group v-model="auditBox.type" class="mb20">
        <el-radio :label="1" :value="1">确认账单</el-radio>
        <el-radio :label="2" :value="2">驳回审核</el-radio>
        <!-- <el-radio :label="5" :value="5">已付款</el-radio> -->
      </el-radio-group>
      <el-input v-if="auditBox.type == 2" type="textarea" :rows="4" placeholder="请输入驳回内容（驳回时必填）"
        v-model="auditBox.reject_reason" resize="none">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setRmOperate()">提交</el-button>
      </span>
    </el-dialog>
    <el-dialog title="支付凭证上传" :visible.sync="uploadDialogVisible" width="30%">
      <el-upload :action="`${download}/file/formimage`" :headers="{ token: $store.getters.userInfo.token }"
        :on-success="successUpload" :on-exceed="handleExceed" :show-file-list="true" :limit="1"
        :on-remove="handleRemove">
        <el-button size="small" type="primary" class="white">点击上传</el-button>
        <!-- <button type="primary" class="white xs back" @click="$router.replace('/orderlist')">点我上传</button> -->
        <!-- <el-image class="avatar" :src="ruleForm.avatar" fit="cover" v-if="ruleForm.avatar"></el-image>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleUploadPay()">提交</el-button>
      </span>
    </el-dialog>
    <div class="row-center pagination mt30">
      <make-pagination @resetList="resetList" :orderObject="orderObject"></make-pagination>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      page_no: 1,
      page_size: 10,
      settlementList: [],
      dialogVisible: false,
      uploadDialogVisible: false,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      form: {
        settle_status: "",
        pay_status: "",
        date: [],
        start_time: '',
        end_time: ''
      },
      orderObject: {},
      auditBox: {
        id: '',
        type: 1,
        reject_reason: ''
      },
      uploadPay: ''
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleUploadPay() {
      if (!this.uploadPay) return this.$message.warning(`请上传支付凭证`);
      this.$api
        .setRmOperateApi({
          id: this.auditBox.id,
          pay_img: this.uploadPay,
          type: 5
        })
        .then((res) => {
          if (res.code == 1) {
            this.resetList(1);
            this.uploadPay = ''
            this.uploadDialogVisible = false
          }
        });
    },
    handleRemove(file, fileList) {
      this.uploadPay = ''
    },
    successUpload(res) {
      let { code, data } = res;
      this.uploadPay = res.data.uri
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制上传 1 个文件`);
    },

    onSubmit() { },
    resetList(e) {
      this.page_no = e;
      this.getSettlementList();
    },
    /**
     * @description 获取对账列表
     * @returns void
     */
    getSettlementList() {
      this.$api
        .getSettlementListApi({
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
          ...this.form,
          start_time: this.form.date && this.form?.date[0],
          end_time: this.form.date && this.form?.date[1],
        })
        .then((res) => {
          this.settlementList = res.data.list;
          let { lists, count, page_size: size, page_no: page } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            lists,
          };
        });
    },
    setRmOperate() {
      this.$api
        .setRmOperateApi(this.auditBox)
        .then((res) => {
          if (res.code == 1) {
            this.resetList(1);
            this.dialogVisible = false
          }
        });
    },
    resetList(e) {
      this.page_no = e;
      this.getSettlementList()
    }
  },
  created() {
    this.getSettlementList();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
/deep/ .el-form {

  .el-form-item__content,
  .el-input__inner {
    // height: 32px;
    // line-height: 32px;
    // width: 240px;
    border-radius: 0;
  }

  .el-input__icon {
    line-height: 32px;
  }

  .el-date-editor .el-range-separator {
    width: auto;
  }
}


.el-upload {
  // border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  button {
    border-radius: 5px;
    padding: 10px 20px;
  }
}

.el-upload:hover {
  border-color: inherit;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>